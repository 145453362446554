import React, { useState, useEffect } from "react";
import "./login.css";
import baseURl from "./constant";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Img from "./kaapi.png";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(10),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#a1887f",
    borderRadius: "25px",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    float: "left",
  },
  form: {
    width: "88%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(6),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login() {
  const classes = useStyles();
  const history = useHistory();
  const [mobNo, setMobNo] = useState("");
  const [password, setPassword] = useState("");
  const location = useLocation();
  const [machineId, setMachineid] = useState("");

  useEffect(() => {
    const values = queryString.parse(location.search);
    console.log("machineId :", values.machineId);
    setMachineid(values.machineId);
    console.log(machineId);
  }, [machineId]);

  const loginUser = (event) => {
    // event.preventDefault();
    console.log("Mob:", mobNo);
    console.log("password:", "xxxxxxx");

    const requestOption = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        contact: mobNo,
        password: password,
      }),
    };

    const postUserLogin = async () => {
      const responce = await fetch(`${baseURl}/user/login`, requestOption);
      const data = await responce.json();
      console.log("Response:", data);
      if (data.status === "User Login Success.") {
        console.log(machineId);
        //alert("Login Sucess");

        history.push({
          pathname: `/menu`,
          search: `?machineId=${machineId}&contactNo=${mobNo}`,
          // data: { machineId: machineId, contactNo: mobNo },
        });
      } else {
        alert("Login Failed");
      }
    };
    postUserLogin();
  };
  const validateUserLogin = (event) => {
    event.preventDefault();

    if (mobNo.length === 0) {
      alert("Please enter a Mobile No");
    } else if (mobNo.length < 10 || mobNo.length > 10) {
      alert("Mobile No. Shoulde be 10 degits");
    } else if (!/^[0-9\b]+$/i.test(mobNo)) {
      alert("Enter a valide Mobile No");
    } else if (password.length === 0) {
      alert("Please enter Password");
      // console.log("sign Up use");
    } else if (password.length < 3) {
      alert("Password shoild be grater than 5 ");
    } else {
      loginUser();
      console.log("signupuser");
    }
  };

  const moveSignUp = () => {
    history.push({
      pathname: `/signup`,
      search: `?machineId=${machineId}`,
      // data: { machineId: machineId, contactNo: mobNo },
    });
  };
  return (
    <div className="login">
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <div>
            <img className="kaapi_logo" src={Img} alt="logo" />
          </div>
          <Typography component="h1" variant="h5">
            Log in
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              keyboardType="number"
              type="number"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Mobile Number"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(event) => setMobNo(event.target.value)}
            />
            <TextField
              keyboardType="number"
              type="number"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(event) => setPassword(event.target.value)}
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              style={{ marginTop: "10px" }}
              onClick={validateUserLogin}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item xs>
                <Link href="#" onClick={moveSignUp} variant="body2">
                  Don't have an account? Sign Up
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </div>
  );
}
