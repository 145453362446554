import React from 'react';
import './MachineLockState.css';
import LockIcon from '@material-ui/icons/Lock';


const MachineLockState = () =>{
    return(
        <div className="container">
            <h1 className="title">Machine is Lock </h1>
            <LockIcon className="lock_Img"/> 
        </div>
    );
};

export default MachineLockState;