import React from "react";
import "./navbar.css";
import {  Link } from "react-router-dom";
import kaapiLogo from "./kaapi.jpg";
const Navbar = ({ contactNo, machineId }) => {
  // console.log(contactNo.contactNo);
  // const toggleButton = document.getElementsByClassName('toggle-button')[0]
  const navbarLinks = document.getElementsByClassName("navbar-links")[0];
  // toggleButton.addEventListener('click', () => {
  //     navbarLinks.classList.toggle('active')
  //   })
  const showList = () => {
    navbarLinks.classList.toggle("active");
  };
  return (
    <nav className="navbar">
      <img className="kaapiLogo" src={kaapiLogo} alt="websit Log" />
      {/* <div class="brand-title">Brand Name</div> */}
      <a href="#" className="toggle-button" onClick={showList}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </a>
      <div className="navbar-links">
        <ul>
          <li>
            <Link
              to={{
                pathname: "/profile",
                search: `contactNo=${contactNo}`,
                // data: { contactNo: "1234567890" },
              }}
            >
              Profile
            </Link>
          </li>
          <li>
            <Link
              to={{
                pathname: "/login",
                search: `machineId=${machineId}`,
                // data: { contactNo: "1234567890" },
              }}
            >
              Sign out
            </Link>
          </li>
          <li>
            <a href="#">About</a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
