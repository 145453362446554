import React, { Component } from "react";
import baseURL from "./constant";
import "./dispenseBeverage.css";
// import machine from "./images/error.png";
// import MachineError from "./machineError.png";
import Error from "./errorIcon.png";
import WaitingImage from "./WaitingForDispense.png";

class dispense extends Component {
  isStateChanged = false;
  jsonObj = JSON.parse("{}");

  state = {
    BaseUrl: baseURL,
    coffeeMessageTop: "",
    coffeeMessageBottom: "Place your Cup at Dispense Area",
    statusMsg: "",
    topMsg: false,
    isLoged: false,
    machineNotRespond: false,
    machineDispensing: false,
    waitingImage: false,
  };

  componentDidMount() {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });

    // window.onbeforeunload = function () {
    //   return false;
    // };
  }

  getStatus = async (machineId, recipeId) => {
    const responce = await fetch(
      `${this.state.BaseUrl}/user/dispense/${machineId}/${recipeId}`
    );
    const data = await responce.json();
    console.log("Response Data:", data);

    if (data.status === 0) {
      // dispense completed
      // Modify the UI
      this.setState({
        coffeeMessageTop: "Please Enjoy your Beverage",
        coffeeMessageBottom: "",
        topMsg: true,
      });
    } else if (data.status === 256) {
      this.setState({
        machineDispensing: true,
      });
      // dispense going on
      setTimeout(() => {
        this.getStatus(this.jsonObj.machine_id, this.jsonObj.recipe_id);
      }, 1500);
    } else if (data.status === 1) {
      // dispense going on
      setTimeout(() => {
        this.getStatus(this.jsonObj.machine_id, this.jsonObj.recipe_id);
      }, 1500);
    } else if (data.status === -2) {
      // -2
      alert("Some Exception: " + data.status);
      this.setState({
        machineNotRespond: true,
      });
    } else if (data.status === -1) {
      // -1
      alert("Some Exception: " + data.status);
    }
  };

  componentWillMount() {
    console.log("componentWillMount");
    const { data } = this.props.location;
    this.jsonObj = JSON.parse(data);
    console.log("JSONObgj", this.jsonObj);
    
    if(this.jsonObj.machine_type === 1){

        if (this.jsonObj.status === 256) {
          this.getStatus(this.jsonObj.machine_id, this.jsonObj.recipe_id);
        } else if (this.jsonObj.status === 1) {
          this.setState({
            waitingImage: true,
          });
          this.getStatus(this.jsonObj.machine_id, this.jsonObj.recipeId);
        } else {
          console.log("Success status");
        }

    }else if(this.jsonObj.machine_type === 2){

          console.log("We are inside machine type 2");
          this.setState({
            machineDispensing: true,
          });
          setTimeout(()=> {
            console.log("5 secondes"); 
          this.setState({
              coffeeMessageBottom: "",
              topMsg: true,
            });
        }, 9000); 

    }else{
      console.log("No Machine Type");

          if (this.jsonObj.status === 256) {
            this.getStatus(this.jsonObj.machine_id, this.jsonObj.recipe_id);
          } else if (this.jsonObj.status === 1) {
            this.setState({
              waitingImage: true,
            });
            this.getStatus(this.jsonObj.machine_id, this.jsonObj.recipeId);
          } else {
            console.log("Success status");
          }
    }

 
  }

  render() {
    let bool = false;
    var coffename = this.jsonObj.name;
    let message = "";

    console.log("Coffee name: ", coffename);
    // console.log('Data shared : ', data);

    if (this.jsonObj.status === -2) {
      message = "Machine not Responding";
      bool = false;
    } else if (this.jsonObj.status === -1) {
      message = "Dispense not Allowed";
      bool = false;
    } else if (this.jsonObj.status === 1) {
      message = "Waiting for Dispense";
      // bool = false;
      this.state.waitingImage = true;
      console.log("waitingImg:", this.state.waitingImage);
    } else if (this.jsonObj.status === 0) {
      message = "Please Enjoy your " + coffename;
      bool = true;
    } else if (this.jsonObj.status === 256) {
      message = coffename + " Dispensing";
      bool = true;
    }

    if (this.state.topMsg === true) {
      message = " Enjoy your Drink ";
      bool = true;
    } else if (this.state.machineNotRespond === true) {
      message = " Machine not Responding";
      // this.setState({
      //   waitingImage: false,
      // });
      this.state.waitingImage = false;
    } else if (this.state.machineDispensing === true) {
      message = coffename + " Dispensing";
      bool = true;
      this.state.waitingImage = false;
    } else {
      console.log("not ready");
    }

    // if (this.state.isLoged === true) {
    //   console.log("this is loged");
    //   window.history.pushState(null, document.title, window.location.href);
    //   window.addEventListener("popstate", function (event) {
    //     window.history.pushState(null, document.title, window.location.href);
    //   });
    // }

    console.log("message : ", message);

    return (
      <div className={this.state.topMsg ? "dispense_complete" : "dispense"}>
        <div className="main">
          <h1 className="headingStyle">
            {" "}
            {this.isStateChanged ? this.state.coffeeMessageTop : message}
          </h1>
          {bool ? (
            <div className={this.state.topMsg ? "coffee_cup" : "cup"}></div>
          ) : (
            <div>
              {this.waitingImage ? (
                <img
                  className="machine"
                  src={WaitingImage}
                  alt="Coffee Machine waiting for Dispensing "
                />
              ) : (
                <img
                  className="machine"
                  src={Error}
                  alt="Coffee machine Image"
                />
              )}
            </div>
          )}
          {bool ? (
            <h2 className="discription">{this.state.coffeeMessageBottom}</h2>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    );
  }
}

export default dispense;
