import React, { useState, useEffect } from "react";
import "./signup.css";
import baseURl from "./constant";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Img from "./kaapi.png";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#a1887f",
    borderRadius: "25px",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    float: "left",
  },
  form: {
    width: "88%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(6),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logo: {
    border: "1px solid #4e342e",
    padding: "5px",
    height: "120px",
    width: "150px",
    borderRadius: "50%",
    marginTop: "5px",
    borderWidth: "5px",
  },
}));

export default function Signup() {
  const classes = useStyles();
  const history = useHistory();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobNo, setMobNo] = useState("");
  const [password, setPassword] = useState("");
  const location = useLocation();
  const [machineId, setMachineid] = useState("");

  useEffect(() => {
    const values = queryString.parse(location.search);
    console.log("machineId :", values.machineId);
    setMachineid(values.machineId);
    console.log(machineId);
  }, [machineId]);

  const signupUser = (event) => {
    // event.preventDefault();
    console.log("name:", name);
    console.log("Email:", email);
    console.log("Mob:", mobNo);
    console.log("password:", "xxxxxxxxxx");

    const requestOption = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: name,
        contact: mobNo,
        email: email,
        password: password,
        // "customer_id":5551
      }),
    };

    const postUserDetails = async () => {
      const responce = await fetch(`${baseURl}/user/register`, requestOption);
      const data = await responce.json();
      console.log("Response:", data);
      if (data.status === "User Registration Success") {
        alert("Registration Sucess Now Login");
        history.push({
          pathname: "/login",
          search: `?machineId=${machineId}`,
          // data: JSON.stringify(data),
        });
      } else {
        alert("Registration Failed");
      }
    };
    postUserDetails();
  };
  const validateUser = (event) => {
    event.preventDefault();
    if (name.length === 0) {
      alert("Please Enter a Name");
    } else if (name.length <= 4) {
      alert("user name should be grater than 4");
    } else if (email.length === 0) {
      alert("Please enter email");
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      alert("Enter a valid Email");
    } else if (mobNo.length === 0) {
      alert("Please enter a Mobile No");
    } else if (mobNo.length < 10 || mobNo.length > 10) {
      alert("Mobile No. Should be 10 digits");
    } else if (!/^[0-9\b]+$/i.test(mobNo)) {
      alert("Enter a valid Mobile No");
    } else if (password.length === 0) {
      alert("Please enter Password");
      // console.log("sign Up use");
    } else if (password.length < 4) {
      alert("Password should be grater than 5 ");
    } else {
      signupUser();
      console.log("SignUp user");
    }
  };

  const moveLogin = () => {
    console.log("mxa:", machineId);
    history.push({
      pathname: `/login`,
      search: `?machineId=${machineId}`,
    });
  };
  return (
    <div className="signup">
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <div style={{ paddingTop: "8px" }}>
            <img className="kaapi_logo" src={Img} alt="logo" />
          </div>
          <Typography component="h1" variant="h5">
            Sign Up
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="name"
              label="User Name"
              name="name"
              autoComplete="name"
              autoFocus
              onChange={(event) => setName(event.target.value)}
            />

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(event) => setEmail(event.target.value)}
            />

            <TextField
              type="number"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="mobileNo"
              label="Mobile Number"
              name="mobileNo"
              autoComplete="mobileNo"
              autoFocus
              onChange={(event) => setMobNo(event.target.value)}
            />

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(event) => setPassword(event.target.value)}
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Agree with term And Condition"
            /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={validateUser}
            >
              Sign up
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" onClick={moveLogin} variant="body2">
                  Already have account
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {""}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </div>
  );
}

// const validatePassword = (value) => {
//   let error;
//   if (!value) {
//     error = 'Please enter your password';
//   } else if (value.length < 4) {
//     error = 'Value must be longer than 3 characters';
//   }
//   return error;
// };

// const validateEmail = (value) => {
//   let error;
//   if (!value) {
//     error = 'Please enter your email address';
//   } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
//     error = 'Invalid email address';
//   }
//   return error;
// };
