import React from "react";
import "./navbar.css";
// import { useHistory, Link } from "react-router-dom";
import kaapiLogo from "./kaapi.jpg";
const navbar = (machineId) => {
  const navbarLinks = document.getElementsByClassName("navbar-links")[0];
  const showList = () => {
    navbarLinks.classList.toggle("active");
  };
  return (
    <nav className="navbar">
      <img className="kaapiLogo" src={kaapiLogo} alt="websit Log" />
      <a href="#" className="toggle-button" onClick={showList}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </a>
      <div className="navbar-links">
        <ul>
          {/* <li>
            <Link>Profile</Link>
          </li>
          <li>
            <Link
              to={{
                pathname: "/login",
                search: `machineId=${machineId.machineId}`,
              }}
            >
              Login
            </Link>
          </li> */}
          <li>
            <a href="#">About</a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default navbar;
