import React, { useState, useEffect } from "react";
import Recipe from "./Recipe";
import "./menu.css";
import Navbar from "./navbar";
import UnderLine from "./UnderLinn1.png";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import baseURl from "./constant";
import { connect } from "react-redux";
import { setTimerout } from "./Action/action";

const Menu = (props) => {
  const [recipes, setRecipes] = useState([]);
  const [myRecipes, setMyRecipes] = useState([]);

  const [recipesNumber, setRecipesNumber] = useState(false);
  const [customRecipesNum, setCustomRecipesNum] = useState(false);
  const location = useLocation();

  const [machineId, setMachineid] = useState("");
  const [contactNo, setContactNo] = useState("");

  //Get MachineId from login page
  useEffect(() => {
    const values = queryString.parse(location.search);
    console.log(values);
    console.log("ada", values.machineId);

    getRecipes(values.machineId, values.contactNo);

    setMachineid(values.machineId);
    setContactNo(values.contactNo);
    // console.log("machineId:", machineId, "contactNo:", contactNo);
  }, [machineId]);

  const getRecipes = async (id, customerId) => {
    const response = await fetch(`${baseURl}/user/menu/${id}/${customerId}`);
    console.log(response);
    const data = await response.json();
    console.log(data.userList);
    setRecipes(data.userList);
    setMyRecipes(data.customList);

    if (data.status === "Menu.") {
      props.setTimerout();
    } else {
      console.log("Timer Not Started");
    }

    if (data.userList.length > 0) {
      setRecipesNumber(true);
    } else {
      //Show Error Dialoge
      setRecipesNumber(false);
    }

    if (data.customList.length > 0) {
      setCustomRecipesNum(true);
    } else {
      setCustomRecipesNum(false);
    }

    console.log("id:", id);
    // setMachineid(id);
    console.log("mide:", machineId);
  };

  //const dispanceBravage
  return (
    <div className="App">
      <Navbar machineId={machineId} contactNo={contactNo} />
      <section className="Section"></section>
      <div className="heading">
        <h1 className="headingMenu">OUR MENU</h1>
        <img className="underLine" src={UnderLine} />
      </div>
      {recipesNumber ? (
        <div
        // style={{
        //   backgroundImage:
        //     "linear-gradient(to top, #c79081 0%, #dfa579 100%)",
        // }}
        >
          <h1 className="section_List">Base Recipes</h1>
          <span className="line"></span>
          <div className="recipes">
            {recipes.map((recipe) => (
              <Recipe
                key={recipe.recipeType}
                title={recipe.recipeName}
                image={recipe.url}
                recipeId={recipe.recipeId}
                m_Id={machineId}
              />
            ))}
          </div>
        </div>
      ) : null}
      {customRecipesNum ? (
        <div
        // style={{
        //   backgroundImage:
        //     "linear-gradient(to top, #c79081 0%, #dfa579 100%)",
        // }}
        >
          <h1 className="section_List">My Recipes</h1>
          <div className="recipes">
            {myRecipes.map((myrecipes) => (
              <Recipe
                key={myrecipes.recipeType}
                title={myrecipes.recipeName}
                image={myrecipes.url}
                recipeId={myrecipes.recipeId}
                m_Id={machineId}
                customerId={contactNo}
              />
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

// export default Menu;

const mapDispatchToProps = (dispatch) => {
  return {
    setTimerout: () => {
      dispatch(setTimerout());
      console.log("fun call");
    },
  };
};

export default connect(null, mapDispatchToProps)(Menu);
