export const setTimerout = () => {
  return {
    type: "SET_TIME_OUT",
    payload: true,
  };
};

export const itemSelected = () => {
  console.log("props...selectdItem");
  return {
    type: "ITEM_SELECTED",
    payload: true,
  };
};

export const setHomeFlag = () => {
  console.log("props...setHomeSetFlag");
  return {
    type: "HOME_FLAG_SET",
    payload: true,
  };
};
