import React from 'react';
import './MachineConnection.css';
import WifiOffIcon from '@material-ui/icons/WifiOff';


const MachineConnection = () =>{
    return(
        <div className="container">
            <h1 className="title">Machine is Offline </h1>
            <WifiOffIcon className="wifi_Img"/> 
        </div>
    );
};

export default MachineConnection;