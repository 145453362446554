import React from "react";
import Img from "./kaapi.png";
import "./home.css";
import Loading from "./loading";
// import { useLocation } from "react-router-dom";
import queryString from "query-string";
import baseURl from "./constant";
// import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { setHomeFlag } from "./Action/action";

class Home extends React.Component {
  // history = useHistory();
  constructor(props) {
    super(props);
    this.state = {
      machineId: "",
    };
  }
  componentDidMount() {
    const values = queryString.parse(this.props.location.search);
    console.log(values);
    this.setState({ machineId: values.machineId });

    console.log("class", this.state.machineId, values.machineId);
    setTimeout(() => {
      this.checkMachine(values.machineId);
    }, 3000);
  }

  checkMachine = async (id) => {
    const response = await fetch(`${baseURl}/machine/menu/${id}`);
    const data = await response.json();
    console.log("Response_Data Home",data);
    if(data.machine_status === "Online" && (data.machine_type === 1|| data.machine_type === 2)){
      console.log("WE are in side Machine_2 or 1 and Online")
      if (data.is_public === 1) {
        // alert("This is a Pubic Machine");
        this.props.setHomeFlag();
        this.props.history.push({
          pathname: `/publicMenu`,
          search: `?machineId=${data.machine_id}`,
        });
      } else if (data.is_public === 0) {
        alert("This is a Private Machine Please Login");
        this.props.history.push({
          pathname: `/login`,
          search: `?machineId=${data.machine_id}`,
        });
        console.log("You are a private user");
      }
    }else if(data.machine_status === "Offline" && data.machine_type === 2){
      console.log("WE are in side Machine_2 and Offline")
      setTimeout( async()=>{
        const response = await fetch(`${baseURl}/machine/menu/${id}`);
        const data = await response.json();
        console.log("Response_Data Home 2.1",data);
        if(data.machine_status === "Online"){
          if (data.is_public === 1) {
            // alert("This is a Pubic Machine");
            this.props.setHomeFlag();
            this.props.history.push({
              pathname: `/publicMenu`,
              search: `?machineId=${data.machine_id}`,
            });
          } else if (data.is_public === 0) {
            alert("This is a Private Machine Please Login");
            this.props.history.push({
              pathname: `/login`,
              search: `?machineId=${data.machine_id}`,
            });
            console.log("You are a private user");
          }
        } else {
          this.props.history.push({
            pathname:`/machineConnection`
          })
        }
      },3000)

    } else {
      this.props.history.push({
        pathname:`/machineConnection`
      })
    }

  
  };
  render() {
    return (
      <div className="home">
        <div className="home_container">
          <img
            className="home_Img"
            style={{ marginLeft: "16px" }}
            src={Img}
          ></img>
          <Loading />
        </div>
      </div>
    );
  }
}
//export default Home;

// const mapStateToProps = (state) => {
//   return {
//     myname: state.name,
//     mywish: state.wish,
//     myage: state.age,
//     myback: state.back,
//   };
// };

const mapDispatchToProps = (dispatch) => {
  return {
    setHomeFlag: () => {
      dispatch(setHomeFlag());
      console.log(" setHomeFlag fun. call");
    },
  };
};

export default connect(null, mapDispatchToProps)(Home);
