import React from "react";
import "./profile.css";
import baseURl from "./constant";
// import { useHistory } from "react-router-dom";
import profieIMg from "./profile-picture.jpg";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
// import ContactMailIcon from "@material-ui/icons/ContactMail";
import SmartphoneIcon from "@material-ui/icons/Smartphone";
import MailIcon from "@material-ui/icons/Mail";
import CloseIcon from "@material-ui/icons/Close";
import Img from "./kaapi.png";
// import { themeRadiusStorageKey } from '../../constants/defaultValues';
import queryString from "query-string";
// import { useLocation } from "react-router-dom";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profileImg: profieIMg,
      edit: false,
      btnName: "Edit Profile",
      disabled: true,
      name: " ",
      userId: "1234567890",
      email: " ",
      mobileNo: " ",
      countryCode: "(+91)",
    };
    this.editHandler = this.editHandler.bind(this);
    // this.name = "Kaapi EndUser"
  }

  componentDidMount() {
    const values = queryString.parse(this.props.location.search);
    // const { contactNo } = this.props.location.data;
    console.log(values);
    fetch(`${baseURl}/user/info/${values.contactNo}`)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            name: result.user_info.name,
            email: result.user_info.email,
            mobileNo: result.user_info.contact,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            email: "Kaapienduser@kaapi.com",
            mobileNo: "9413094909",
            name: "Kaapi EndUser",
            // isLoaded: true,
            //error
          });
          console.log(error);
        }
      );
  }

  updatedUserProfile = () => {
    const requestOption = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        // user_id=this.userId,
        name: this.state.name,
        contact: this.state.mobileNo,
        email: this.state.email,
        //  password: this.password,
        // customer_id:5551
      }),
    };
    fetch(`${baseURl}/user/info/`, requestOption)
      .then((res) => res.json())
      .then(
        (result) => {
          console.log("UpdatedUserProfile:", result);
          if (result.status === "User info update Success") {
            alert("Your Profile Updated Sucessfully");
          } else {
            alert("your profile not updated");
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  editHandler = async () => {
    this.setState({ edit: !this.state.edit });
    this.setState({ disabled: !this.state.disabled });
    console.log(this.state.edit);
    if (this.state.edit === true) {
      this.updatedUserProfile();
      console.log("Save Profile");
    } else {
      console.log("Editing");
    }
  };

  editbtn = () => {
    if (this.edit === true) {
      console.log("Save Profile");
    } else {
      console.log("Editing");
    }

    if (this.state.btnName === "Save Profile") {
      console.log("Save Profile");
    } else {
      console.log("Editing");
    }
  };

  render() {
    this.state.edit
      ? (this.state.btnName = "Save Profile")
      : (this.state.btnName = "Edit Profile");

    // this.state.edit ? console.log("editprofile") : this.updatedUserProfile();
    // const { profileImg } = this.state;
    // const { BtnName } = this.state;
    return (
      <div className="profile_page">
        <div className="profile">
          <div className="card-container">
            <button className="close" onClick={this.props.history.goBack}>
              <CloseIcon />
            </button>
            <div style={{ paddingTop: "10px" }}>
              <img className="profile_kaapi_logo" src={Img} alt="logo" />
            </div>
            <div>
              <h2 className="profile_heading">Profile</h2>
            </div>
            <div className="lower-container">
              {this.state.edit ? (
                <div>
                  <div className="lower_item">
                    <AccountBoxIcon style={{ fontSize: "30" }} />
                    <h3 style={{ marginLeft: "15px", fontWeight: "25px" }}>
                      <input
                        id="User_name"
                        className="user_name"
                        value={this.name}
                        style={{ color: "black" }}
                        onChange={(event) =>
                          this.setState({ name: event.target.value })
                        }
                      ></input>
                    </h3>
                  </div>
                  <div className="lower_item">
                    <MailIcon style={{ fontSize: "30" }} />
                    <h4 style={{ marginLeft: "15px" }}>
                      <input
                        id="User_email"
                        className="user_email"
                        value={this.state.email}
                        onChange={(event) =>
                          this.setState({ email: event.target.value })
                        }
                      ></input>
                    </h4>
                  </div>
                  <div className="lower_item">
                    <SmartphoneIcon style={{ fontSize: "30" }} />
                    <h5
                      style={{
                        marginLeft: "15px",
                        display: "flex",
                        textAlign: "center",
                        alignItems: "center",
                        flex: "0 0 auto",
                        fontSize: "15px",
                        fontWeight: "normal",
                      }}
                    >
                      MOB:{this.state.countryCode}
                      <input
                        id="User_mobileNo"
                        className="user_mobileNo"
                        value={`${this.state.mobileNo}`}
                        style={{ fontWeight: "15px" }}
                        onChange={(event) =>
                          this.setState({ mobileNo: event.target.value })
                        }
                        disabled
                      ></input>
                    </h5>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="lower_item">
                    <AccountBoxIcon style={{ fontSize: "30" }} />
                    <h3 style={{ marginLeft: "15px", fontWeight: "25px" }}>
                      <input
                        id="User_name"
                        className="user_name"
                        value={this.state.name}
                        disabled={this.state.disabled ? "disabled" : ""}
                      ></input>
                    </h3>
                  </div>
                  <div className="lower_item">
                    <MailIcon style={{ fontSize: "30" }} />
                    <h4 style={{ marginLeft: "15px" }}>
                      <input
                        id="User_email"
                        className="user_email"
                        value={this.state.email}
                        disabled={this.state.disabled ? "disabled" : ""}
                      ></input>
                    </h4>
                  </div>
                  <div className="lower_item">
                    <SmartphoneIcon style={{ fontSize: "30" }} />
                    <h5 style={{ marginLeft: "15px" }}>
                      <input
                        id="User_mobileNo"
                        className="user_mobileNo"
                        value={`MOB:${this.state.countryCode}${this.state.mobileNo}`}
                        style={{ fontWeight: "normal" }}
                        disabled
                      ></input>
                    </h5>
                  </div>
                </div>
              )}

              <div>
                <button className="btn" onClick={this.editHandler}>
                  {this.state.btnName}
                </button>
                {/* <a href="#" class="btn">Edit profile</a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Profile;
