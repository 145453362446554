import React from "react";
import "./App.css";
import Signup from "./signup";
import Login from "./login";
//import Login from "./user/login";
import { Route } from "react-router-dom";
import Menu from "./menu";
import DispenseBerverages from "./dispenseBeverages";
import Profile from "./profile";
import Home from "./home";
import PublicMenu from "./publicMenu";
import MachineConnection from "./MachineConnection/MachineConnection";
import MachineLockState from "./MachineLockState/MachineLockState";
class App extends React.Component {
  render() {
    return (
      <div>
        <Route exact path="/home" component={Home} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/signup" component={Signup} />
        <Route exact path="/menu" component={Menu} />
        <Route exact path="/publicMenu" component={PublicMenu} />
        <Route exact path="/dispense" component={DispenseBerverages} />
        <Route exact path="/profile" component={Profile} />
        <Route exact path="/machineConnection" component={MachineConnection}/>
        <Route exact path="/machineLock" component={MachineLockState}/>
      </div>
    );
  }
}

export default App;
