import React, { useState, useEffect } from "react";
import Recipe from "./Recipe";
import "./menu.css";
import PublicNav from "./publicNav";
import UnderLine from "./UnderLinn1.png";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import baseURl from "./constant";
import { connect } from "react-redux";
import { setTimerout } from "./Action/action";

const PublicMenu = (props) => {
  const [recipes, setRecipes] = useState([]);
  const [recipesNumber, setRecipesNumber] = useState(false);
  const location = useLocation();
  const [machineId, setMachineid] = useState("");
  const [serialNo, setSerialNo] = useState("");
  const [is_paid,setIs_paid] = useState("")
  const [machine_id,setMachine_id] = useState("");

  //Get MachineId from login page
  useEffect(() => {
    const values = queryString.parse(location.search);
    console.log(values);
    console.log("ada", values.machineId);
    setMachineid(values.machineId);
    getRecipes(values.machineId);
  }, [machineId]);

  const getRecipes = async (id) => {
    const responce = await fetch(`${baseURl}/machine/menu/${id}`);
    //console.log(responce);
    const data = await responce.json();
    console.log(data.user_list);
    setRecipes(data.user_list);
    setSerialNo(data.serial);
    setIs_paid(data.is_paid);
    setMachine_id(data.machine_id);

    if (data.status === "Menu.") {
      props.setTimerout();
    } else {
      console.log("Timer Not Started");
    }

    if (data.user_list.length > 0) {
      setRecipesNumber(true);
    } else {
      //Show Error Dialoge
      setRecipesNumber(false);
    }

    console.log("id:", id);
  };

  //const dispanceBravage
  return (
    <div className="App">
      <PublicNav machineId={machineId} />
      <section className="Section"></section>
      <div className="heading">
        <h1 className="headingMenu">OUR MENU</h1>
        <img className="underLine" src={UnderLine} />
      </div>
      {recipesNumber ? (
        <div
        // style={{
        //   backgroundImage:
        //     "linear-gradient(to top, #c79081 0%, #dfa579 100%)",
        // }}
        >
          <h1 className="section_List">Base Recipes</h1>
          <span className="line"></span>
          <div className="recipes">
            {recipes.map((recipe) => (
              <Recipe
                key={recipe.button_no}
                title={recipe.name}
                image={recipe.url}
                user_recipe_id={recipe.user_recipe_id}
                button_No={recipe.button_no}
                m_Id={machineId}
                serialNo={serialNo}
                price={recipe.price}
                is_paid={is_paid}
                machine_id={machine_id}
              />
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

//export default PublicMenu;

const mapDispatchToProps = (dispatch) => {
  return {
    setTimerout: () => {
      dispatch(setTimerout());
      console.log("fun call");
    },
  };
};

export default connect(null, mapDispatchToProps)(PublicMenu);
