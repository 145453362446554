import React from "react";
import { useHistory } from "react-router-dom";
import Style from "./recipe.module.css";
import baseURl from "./constant";
import { connect } from "react-redux";
// import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
// import { makeStyles } from "@material-ui/core/styles";
import { itemSelected } from "./Action/action";
import Logo from "./kaapi.jpg";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: "100%",
//     "& > * + *": {
//       marginTop: theme.spacing(2),
//     },
//   },
// }));

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const __DEV__ = document.domain === "localhost";

const Recipe = (
  {
    title,
    image,
    user_recipe_id,
    m_Id,
    // customerId,
    timer,
    selectedItems,
    itemSelected,
    homeFlag,
    serialNo,
    price,
    button_No,
    is_paid,
    machine_id,
  },
  props
) => {
  // const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");

  const handleClick = (altMsg) => {
    setOpen(true);
    setAlertMessage(altMsg);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const history = useHistory();
  // const machineId = m_Id;
  const SerialNo = serialNo;

  const onItemClicked = () => {

    
    console.log(user_recipe_id);
    console.log(title);
    console.log("serialNo", SerialNo);
    //  console.log("Props result:", timer, props.timer, props);
    const requestOption = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        machine_id: SerialNo,
        user_recipe_id: user_recipe_id,
        button_no: button_No
       // userId: 1 
      }),
    };
   // machine_id, user_recipe_id, button_no
    const postRecipe = async () => {
      const response = await fetch(`${baseURl}/user/dispense`, requestOption);
      const data = await response.json();
      console.log(data);
      if(response.status === 200){
        if (data.status_code === 0) { // && data.machine_type ===1
          console.log("Success");
          // let message = "";
  
          const getStatus = async () => {
            const response = await fetch(
              `${baseURl}/user/dispense/${SerialNo}/${user_recipe_id}`
            );
            console.log("responce", response);
            const data = await response.json();
            console.log(data);
  
            data.name = title;
  
            console.log(JSON.stringify(data));
  
            history.push({
              pathname: "/dispense",
              data: JSON.stringify(data),
            });
  
            // if (data.status === 256) {
            //   history.push({
            //     pathname: "/dispense",
            //     data: JSON.stringify(data),
            //   });
            // } else if (data.status === -2) {
            //   for (let i = 0; i < 5; i++) {
            //     const timerOut = setTimeout(() => {
            //       console.log("This will run after 1000 millisecond!");
            //       getStatus();
            //       if (i === 5) {
            //         history.push({
            //           pathname: "/dispense",
            //           data: JSON.stringify(data),
            //         });
            //       }
            //     }, 1000);
            //   }
            // }
          };
  
          const timerOut = setTimeout(() => {
            console.log("This will run after 3000 millisecond!");
            getStatus();
          }, 3000);
        } else if (data.status_code === -2 && data.machine_type ===1) {
          handleClick("Machine is busy, Scan QR-code Again");
        }else if(data.status_code === 3){
          history.push({
            pathname: "/machineLock",
              });
  
        } else {
          console.log("failure");
        }
        // else if (data.status_code === 0 && data.machine_type ===2){
        //   history.push({
        //     pathname: "/dispense",
        //     data: JSON.stringify(data),
        //   });
      }else{
        console.log("We not getting Response Status Properly")
      }
    
      // }
      
    };


//Payment

const displayRazorpay = async (machine_id,price,title)=> {
  //alert("Do you want pay money");
 const res = await loadScript(
   "https://checkout.razorpay.com/v1/checkout.js"
 );

 if (!res) {
   alert("Razor pay SDK failed to load. Are you online?");
   return;
 }

//  console.log("machine_id",machine_id);
//  console.log("price",price);
//  console.log("name",typeof(title))

const requestOption = {
method: "POST",
headers: {
 "Content-Type": "application/json",
},
body: JSON.stringify({
   machine_id:machine_id.toString(),
   amount:price*100, 
   recipe_name: title.toString()
  //  machine_id:"kaapi_01",
  //  amount:300,
  //  recipe_name: "Hot Water"
}),
};


const response = await fetch(`${baseURl}/payment/create`, requestOption);
const Data = await response.json();
console.log(Data);

const options = {
  //  key: __DEV__ ? "rzp_test_oULC4Pp9nN00Wh" : "rzp_live_CuBNG22osiojRB",

   key: __DEV__ ? "rzp_live_CuBNG22osiojRB" : "rzp_live_CuBNG22osiojRB",
   currency: Data.order_res.currency,
   amount: Data.order_res.amount.toString(),
   order_id: Data.order_res.id,
   name: title,//recipes
   description: "Thank for your order",
   image: Logo,
   handler: function (response) {
     console.log(response);
    // setCompleted(true);
     alert("Payment Successful");
     //postRecipe();
     // alert(response.razorpay_payment_id);
     // alert(response.razorpay_order_id);
     // alert(response.razorpay_signature);
  //   confetti.start(6000);
  const requestOption = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      
        trans_id:response.razorpay_payment_id,
        order_id:response.razorpay_order_id,
        amount:Data.order_res.amount.toString()
     
    }),
  };

  const updateData = async()=>{
    const response = await fetch(`${baseURl}/payment/update`, requestOption);
    const Data = await response.json();
    console.log(Data);
    if(Data.status === "Payment info update Success"){
      postRecipe();
    }else{
      handleClick("Session Timeout error");
    }
  };
  updateData();

   },
    prefill: {
     name: "kaapi",
     email: "test@kaapi.com",
     contact: "9858098580"
 },
   theme: {
     color: "#a52b3b",
   },
   method: {
     netbanking: false,
     card: false,
     wallet: false,
     paylater:false,
     upi: true,
   },
 };

 const paymentObject = new window.Razorpay(options);
 paymentObject.on('payment.failed', function (response){
   console.log(response.error);
        alert(response.error.reason);  
        // alert(response.error.code);
        // alert(response.error.description);
        // alert(response.error.source);
        // alert(response.error.step)    
});


 paymentObject.open();



}


    const msg = "Session Timeout error, Scan QR-code Again";

    if(is_paid===0){

      if (homeFlag === true) {
        if (timer === false) {
          console.log(homeFlag);
          if (selectedItems === false) {
            console.log("You Selected an Item ...");
            itemSelected();
            postRecipe();
          } else {
            handleClick(msg);
            console.log(msg);
          }
        } else {
          handleClick("Session Timeout error, Scan QR-code Again");
        }
      } else {
        handleClick(msg);
        console.log(msg);
      }
    }  else if(is_paid===1){

      if (homeFlag === true) {
        if (timer === false) {
          console.log(homeFlag);
          if (selectedItems === false) {
            console.log("You Selected an Item ...");
            itemSelected();
           displayRazorpay(machine_id,price,title) ;
        
          } else {
            handleClick(msg);
            console.log(msg);
          }
        } else {
          handleClick("Session Timeout error, Scan QR-code Again");
        }
      } else {
        handleClick(msg);
        console.log(msg);
      }
    }

  
  };

  return (
    <div className={Style.recipe} onClick={onItemClicked}>
      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
        <Alert severity="warning">{alertMessage}</Alert>
      </Snackbar>
      <div className={Style.div_container}>
        <img className={Style.image} src={image} alt="" />
        <div className={Style.heading}>{title}</div>
      </div>
     <div>
     {is_paid === 1 ? (<div className={Style.pricing_style}> {`₹${price}`}</div>):null}
     </div>
    </div>
  );
};

// export { Recipe };
const mapDispatchToProps = (dispatch) => {
  return {
    itemSelected: () => {
      dispatch(itemSelected());
    },
  };
};

const mapStateToProps = (state) => {
  // console.log(state.timer);
  return {
    timer: state.timer,
    selectedItems: state.selectedItme,
    homeFlag: state.homeFlag,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Recipe);
