import { all, fork, put, takeLatest } from "redux-saga/effects";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

function* setTimerAsync() {
  yield delay(110000);
  console.log("Hello Sagas! after 20000");
  yield put({
    type: "SET_TIME_OUT_ASYNC",
    payload: true,
  });
}

function* setTimerout() {
  console.log("Hello Sagas!");
  yield takeLatest("SET_TIME_OUT", setTimerAsync);
}

function* itemSelectedAsync() {
  yield delay(1000);
  console.log("Hello Sagas! itemSelectedAsync");
  yield put({
    type: "ITEM_SELECTED_ASYNC",
    payload: true,
  });
}

function* itemSelected() {
  console.log("Hello Sagas! itemSelected");
  yield takeLatest("ITEM_SELECTED", itemSelectedAsync);
}

function* setHomeFlagSetAsync() {
  yield delay(1000);
  console.log("Hello Sagas! setHomeFlagSetAsync");
  yield put({
    type: "HOME_FLAG_SET_ASYNC",
    payload: true,
  });
}

function* setHomeFlag() {
  console.log("Hello Sagas! setHomeSetFlag");
  yield takeLatest("HOME_FLAG_SET", setHomeFlagSetAsync);
}

export default function* rootSaga() {
  yield all([fork(itemSelected), fork(setTimerout), fork(setHomeFlag)]);
}
